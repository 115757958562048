import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
      avatar: null,
      email: null,
      password: null,
      password_confirm: null,
      locked: false,
      roles: null,
      maintainer: false,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.site-admin', route: { name: 'site-admin' } },
      ],
      listRoute: () => ({ name: 'site-admin' }),
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_ADMIN']),
      afterCreateRoute: result => ({
        name: 'site-admin-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => this.vm?.$helper.getSiteUserName(formData),
      pageTitleBase: () => 'module.site_admin',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              label: 'site_admin.data.name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            email: {
              required: true,
              email: true,
              label: 'site_admin.data.email',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            locked: {
              label: 'site_admin.data.locked',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            maintainer: {
              label: 'site_admin.data.maintainer',
              create: () => true,
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            roles: {
              label: 'site_admin.data.roles',
              create: () => true,
              type: 'selection',
              multiple: true,
              options: (formInfo: FormInfoInterface) => {
                if(!formInfo.formMeta) return []
                if(!Array.isArray(formInfo.formMeta.roles)) return []
                return formInfo.formMeta.roles.map(role => ({
                  value: role,
                  text: role,
                }))
              },
              hasChanged: ({
                originalData,
                data,
                bothEmpty
              }: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            groups: {
              label: 'module.site_admin_group',
              type: 'item-list',
              itemTitle: item => item.name,
              itemSubtitle: item => item.description,
              defaultAvatarIcon: 'fa fa-user-friends',
              itemRoute: (item) => ({
                name: 'site-admin-group-update',
                params: { target: item.id },
              }),
              grid: { xl: 12, lg: 12, md: 12 },
              // @ts-ignore
              selector: this.vm?.$siteAdminGroupSelector,
              hasChanged: ({originalData, data, arrayPropertyDiff}: FormDataChangeHelperInterface) => {
                return arrayPropertyDiff(originalData, data, 'id')
              },
            },
          },
        },

        // 密碼設定
        {
          title: 'site_admin.form_block.password',
          create: ({ formMode }: FormInfoInterface) => formMode === 'create',
          data: {
            password: {
              required: true,
              label: 'site_admin.data.password',
              type: 'text',
              help: 'password.help',
              minlength: 4,
            },
            password_confirm: {
              required: true,
              label: 'site_admin.data.password_confirm',
              type: 'text',
              minlength: 4,
              validate: ({formData, formMode}: FormInfoInterface) => {
                if(formMode != 'create') return []
                return [
                  {
                    error: 'validate.error.password_not_match',
                    result: formData.password == formData.password_confirm,
                  },
                ]
              }
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
